// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./adminhome.scss"></require>
  <section id="adminhome" class="flex-column">
    <div id="header" class="flex-row">
      <img click.delegate="router.navigateToRoute('home')" src="${___HTML_LOADER_IMPORT_0___}">
    </div>
    <h1>
      Admin 🏢
    </h1>
    <div class="panel flex-row" click.delegate="goToBetaRegister()">
      <p class="nombre">\${allUsers.totalDocs}</p>
      <p class="title" t="admin.adminhome.registrationsMyBanka"></p>
    </div>
    <div class="panel flex-row" click.delegate="goToNewsLetterInterface()">
      <p class="nombre">\${totalNewsletter}</p>
      <p class="title" t="admin.adminhome.signupsNewsletter"></p>
    </div>
    <div class="panel flex-row">
      <p class="nombre">\${totalSubscription}</p>
      <p class="title" t="admin.adminhome.subscriptions"></p>
    </div>
    <div class="panel flex-row" click.delegate="goToReportSuggestion()">
      <p class="nombre">\${totalSuggestion}</p>
      <p class="title" t="admin.adminhome.suggestions"></p>
    </div>
    <div class="panel flex-row" click.delegate="goToReportBank()">
      <p class="nombre">\${totalBankReport}</p>
      <p class="title" t="admin.adminhome.bugreports"></p>
    </div>
    <button class="btn btn-primary" click.delegate="goToAdminBlog()">
      <span>Blog - Redaction</span>
    </button>
    <div class="spacer-default"></div>
    <button class="btn btn-primary" click.delegate="goToAdminSeeBlog()">
      <span>Blog - Editing</span>
    </button>
    <p>
      Version 0.21
    </p>
    <div class="flex-grow"></div>
    <div class="vide flex-column">
    </div>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;